<template>
    <span
        class="price"
        :class="{ 'price-discount': price.discount }"
    >
        <span class="price-value">
            {{ price.format.whole }}
            <small v-if="price.format.decimal">{{ price.format.decimal }}</small>
        </span>
        <span class="price-currency">{{ currency }}</span>
        <template v-if="refPrice || priceHybrid">
            <span v-if="!priceHybrid">{{ getRefPriceText() }}</span>
            <span
                v-if="price.tooltip"
                class="tooltip tooltip-top"
                :data-tooltip="price.tooltip"
            >
                <base-icon classes="circle sm">?</base-icon>
            </span>
        </template>
    </span>
</template>

<script>
export default {
    name: 'Price',

    props: {
        price: {
            type: Object,
            required: true,
        },
        currency: {
            type: String,
            required: true,
        },
        priceHybrid: {
            type: Boolean,
            default: false,
        },
        refPrice: {
            type: Boolean,
            default: false,
        },
        refPriceLabelLong: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        getRefPriceText() {
            const discount = this.price.discount && `(${this.price.discount})`;
            const rrp = this.$store.state.params.api.country.priceRrpLabel[this.refPriceLabelLong ? 'long' : 'short'];

            return discount || rrp;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./../scss/required";

.price {
    display: block;
    line-height: 1;
    color: $primary-2;
}

.price-value {
    display: inline-flex;

    small {
        font-size: 65%;
    }
}

.price-discount {
    .price-value,
    .price-currency {
        position: relative;

        &::before {
            position: absolute;
            top: 50%;
            width: 100%;
            height: 1px;
            content: "";
            background-color: $gray-600;
        }
    }
}

.price-ref {
    color: $gray-600;
    text-transform: capitalize;
}

.price-listing {
    font-weight: $font-weight-bold;
}

.price-product {
    font-size: $h3-font-size;
    text-transform: capitalize;
}

.price-product-mini {
    font-weight: $font-weight-bold;
    text-transform: capitalize;
}
</style>
