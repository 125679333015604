var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "price", class: { "price-discount": _vm.price.discount } },
    [
      _c("span", { staticClass: "price-value" }, [
        _vm._v("\n        " + _vm._s(_vm.price.format.whole) + "\n        "),
        _vm.price.format.decimal
          ? _c("small", [_vm._v(_vm._s(_vm.price.format.decimal))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "price-currency" }, [
        _vm._v(_vm._s(_vm.currency)),
      ]),
      _vm._v(" "),
      _vm.refPrice || _vm.priceHybrid
        ? [
            !_vm.priceHybrid
              ? _c("span", [_vm._v(_vm._s(_vm.getRefPriceText()))])
              : _vm._e(),
            _vm._v(" "),
            _vm.price.tooltip
              ? _c(
                  "span",
                  {
                    staticClass: "tooltip tooltip-top",
                    attrs: { "data-tooltip": _vm.price.tooltip },
                  },
                  [
                    _c("base-icon", { attrs: { classes: "circle sm" } }, [
                      _vm._v("?"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }